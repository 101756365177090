import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-remember-password',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RememberPasswordComponent {
  dialogRef = inject(DialogRef);
  close() {
    this.dialogRef.close();
  }
}
