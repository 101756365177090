<form [formGroup]="form" #formEl (ngSubmit)="_submit()">
  <app-text-field
    name="login"
    inputId="loginInput"
    autocomplete="login"
    type="text"
    [control]="form.controls.login"
    label="log_in.login"
  ></app-text-field>
  <app-text-field
    name="password"
    inputId="passwordInput"
    autocomplete="password"
    type="password"
    [control]="form.controls.password"
    label="register.password"
    [showPasswordButton]="true"
  ></app-text-field>
  <div class="actions">
    <button class="forgot-link" type="button" (click)="openForgotPasswordDialog()">
      {{ 'log_in.forgot_password' | translate }}
    </button>
  </div>

  <button type="submit" [disabled]="form.invalid || _disabled()" class="app-btn">
    {{ 'log_in.login_btn' | translate }}
  </button>
</form>

<div class="app-failed-login">
  {{ _errorMessage() | translate }}
</div>
