<div class="bg">
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
  <div class="bg-pattern-row"></div>
</div>
<div class="foreground"></div>
