import { Component } from '@angular/core';

@Component({
  selector: 'app-aside-banner',
  standalone: true,
  imports: [],
  templateUrl: './aside-banner.component.html',
  styleUrl: './aside-banner.component.scss',
})
export class AsideBannerComponent {}
