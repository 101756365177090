import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { QueryParamsService } from '@services/query-params/query-params.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  protected router = inject(Router);
  protected queryParamsService = inject(QueryParamsService);

  protected getNavigationCommandsForErrorPage() {
    return ['/error'];
  }
  protected getNavigationCommandsForExpiredLinkPage() {
    return ['/link-expired'];
  }
  protected getNavigationExtras() {
    return {
      queryParams: this.queryParamsService.getAllToObject(),
    };
  }

  goToErrorPage() {
    return this.router.navigate(this.getNavigationCommandsForErrorPage(), this.getNavigationExtras());
  }
  getErrorPageUrlTree() {
    return this.router.createUrlTree(this.getNavigationCommandsForErrorPage(), this.getNavigationExtras());
  }

  goToExpiredLinkPage() {
    return this.router.navigate(this.getNavigationCommandsForExpiredLinkPage(), this.getNavigationExtras());
  }
  getExpiredLinkPageUrlTree() {
    return this.router.createUrlTree(this.getNavigationCommandsForExpiredLinkPage(), this.getNavigationExtras());
  }
}
