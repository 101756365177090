import { Component, EventEmitter, inject, Output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginRequest } from '@services/login/login.types';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldComponent } from '../controls/text-field/text-field.component';
import { RememberPasswordComponent } from '@components/remember-password/remember-password.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, TextFieldComponent],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {
  dialog = inject(Dialog);
  protected _errorMessage = signal('');
  protected _disabled = signal(false);

  @Output()
  formSubmit = new EventEmitter<LoginRequest>();

  form = new FormGroup({
    login: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    rememberMe: new FormControl(false),
  });

  setErrorMessage(value: string) {
    this._errorMessage.set(value);
  }

  disable() {
    this._disabled.set(true);
  }
  enable() {
    this._disabled.set(false);
  }

  openForgotPasswordDialog() {
    this.dialog.open(RememberPasswordComponent, {});
  }

  protected _submit() {
    if (this._disabled()) {
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.formSubmit.emit(this.form.getRawValue());
  }
}
